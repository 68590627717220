<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- 관련 비상조치계획서 목록 -->
      <c-table
        ref="table"
        title="LBL0002362"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="tabParam.emergencyList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="iimAccidentId"
        selection="multiple"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="searchEmergency" />
            <!-- 삭제 -->
            <c-btn v-if="editable && !disabled && tabParam.emergencyList.length > 0" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="removeEmergency" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-accident-emergency-plan',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        iimAccidentId: '',
        emergencyList: [],
        accidentStatusCd: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'emergencyPlanName',
            field: 'emergencyPlanName',
            // 계획서명
            label: 'LBL0002363',
            align: 'center',
            sortable: true,
            style: 'width:150px',
            type: 'link',
          },
          {
            name: 'emergencyTypeName',
            field: 'emergencyTypeName',
            // 비상사태구분
            label: 'LBL0002364',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'emergencyClassCd',
            field: 'emergencyClassCd',
            // 비상사태유형
            label: 'LBL0002365',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
        ],
        data: [],
        height: '780px'
      },
      editable: true,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd === 'ISPC000005'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      // code setting
      // list setting

    },
    searchEmergency() {
      this.popupOptions.title = 'LBL0002366'; // 관련 재해사고 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.tabParam.plantCd
      };
      this.popupOptions.target = () => import(`${'./processEmergencyActionPlanDocuPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEmergencyPopup;
    },
    /* eslint-disable no-unused-vars */ 
    closeEmergencyPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveEmergencies = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.tabParam.emergencyList, { sopEmergencyPlanId: item.sopEmergencyPlanId }) === -1) {
            saveEmergencies.push({
              iimAccidentId: this.tabParam.iimAccidentId,
              emergencyPlanName: item.emergencyPlanName,
              sopEmergencyPlanId: item.sopEmergencyPlanId,
              editFlag: 'C',
            })
          }
        })
        this.$http.url = transactionConfig.sop.eap.relateAccident.insert.url;
        this.$http.type = 'POST';
        this.$http.param = saveEmergencies;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$emit('getDetail')
        },);
      }
    },
    removeEmergency(item) {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.eap.relateAccident.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
            this.$_.forEach(selectData, _item => {
                this.tabParam.emergencyList = this.$_.reject(this.tabParam.emergencyList , _item)
                this.$refs['table'].selected = [];
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              },);
            })
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    linkClick(tag) {
      this.popupOptions.title = 'LBL0002367'; // 사고관련 비상조치계획서 상세
      this.popupOptions.param = {
        sopEmergencyPlanId: tag ? tag.sopEmergencyPlanId : '',
        plantCd: tag ? tag.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/eap/emergencyActionPlanDocuDetail'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
